:root {
  --spruce: #004a59dd;
  --breeze: #ccfff6;
  --matcha: #cbf200;
  --mulberry: #800055dd;
  --sky: #599effcc;
  --dark: #708c8c;
  --light: #e7eff3;
  --black: #000000;
   --pink: #d900c7;
  --mango: #ffaa00dd;
}

@font-face {
  font-family: "BeatriceMedium";
  font-display: swap;
  src: url("./fonts/beatrice-medium.eot");
  src: url("./fonts/beatrice-medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/beatrice-medium.woff2") format("woff2"),
    url("./fonts/beatrice-medium.woff") format("woff");
}
@font-face {
  font-family: "BeatriceBold";
  font-display: swap;
  src: url("./fonts/beatrice-bold.eot");
  src: url("./fonts/beatrice-bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/beatrice-bold.woff2") format("woff2"),
    url("./fonts/beatrice-bold.woff") format("woff");
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  font-family: BeatriceMedium;
  letter-spacing: 0.01em;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  font-size: 12px;
}

/* --------------------------
 * Element Styles
 * --------------------------
*/

body {
  background-color: var(--black);
  color: var(--light);
  line-height: 1.6;
}

.logo {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 3;
  width: 50%;
  max-width: 550px;

  left: 40px;
  top:40px;
  width: 300px;
}

/*  type: "whitelabel", "onlinebrands", "b2b", "p2p"  */


.checkout-westernunion,
.partners ,
.digicel,
.consumer,
.p2p,
.b2b,
.whitelabel,
.onlinebrands,
.voucher {
  border-radius: 10px;
  width: fit-content;
  padding-left: 8px;
  font-size: 11px;
}

 .voucher {
  padding: 0;
  margin: 0;
  border-radius: 16px;
} 

.voucher img{
    border-radius: 16px;
}

.checkout-westernunion{
  background: black;
}

.partners {
  background-color: var(--spruce);
}
.consumer, .onlinebrands {
  background-color: var(--mulberry);
}
.p2p {
  background-color: var(--mango);
  color: var(--black);
}
.digicel, .whitelabel, .b2b {
  background-color: var(--sky);
}

hr {
  border: none;
  border-top: 2px solid var(--matcha);
  width: 100%;
  margin: 0;
}

.top,
.bottom {
  padding: 2px 8px 2px 0;
  line-height: 14px;
}

.top {
  padding-top: 6px;
}

.bottom {
  padding-bottom: 6px;
}

.cur {
  color: var(--matcha);
}

.p2p > .top > .cur {
  color: var(--mulberry);
}

.country {
  display: block;
}

.transactionCount {
  bottom: 30px;
  position: fixed;
  font-size: 48px;
  color: var(--light);
  z-index: 2;
  font-family: BeatriceBold;
}

.transactionCount {
  right: 40px;
}

#transactionCountTotal{
  color: var(--breeze);
  font-size: 54px;
  z-index: 2;
  font-family: BeatriceBold;
}
.transactionCount span {
  font-size: 14px;
  display: block;
}

.slide-panel{z-index:2;}
.slide-pane__header {
  background: rgba(15,14,14,.9); 
}

.slide-pane {
  background: rgba(15,14,14,.9); 
}

.map-type-label, .slide-pane__title{
  color: #fff;
}
.settings-toggle-holder{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 2;
}
.settings-toggle{
  color: var(--spruce);
  font-size: 20px;
}

select {
  width: 220px;
  height: 30px;
  font-size: 16px;
  color: var(--matcha);
  background-color: rgb(15, 14, 14);
  border-radius: 5px;
}
.live-indicator, .clock{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.clock {
  top: 90px;
  right: 60px;
  font-size: 20px;
    font-family: BeatriceBold;
}
.live-indicator p{
  font-size: 20px;
  font-family: BeatriceBold;
}

@media only screen and (max-width: 600px) {
  .transactionCount, .live-indicator , .clock{
    display: none;
  }

}

.afg-img{
  margin-left: -30px;
}

